import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/Layout";

import blockquote_start from "../assets/images/blockquote_start.png";
import blockquote_end from "../assets/images/blockquote_end.png";
import grey_outline_circle from "../assets/images/grey_outline_circle.svg";
import grey_outline_rectangle from "../assets/images/grey_outline_rectangle.svg";
import grey_outline_square from "../assets/images/grey_outline_square.svg";
import grey_outline_cylinder from "../assets/images/grey_outline_cylinder.svg";
import grey_outline_transcript from "../assets/images/grey_outline_transcript.svg";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

import { getDefinedTermPopUpItem } from "../helpers";

export default ({ pageContext, data, location }) => {

  const {
    slogan,
    name,
    keyFigures,
    introduction,
    serviceOffering,
    createdAt,
    updatedAt,
    contact,
    pageLinkLists,
    pageLinkListHeader
  } = data.contentfulCoSellPartner;

  const seoData = {
    updatedAt: updatedAt,
    createdAt: createdAt,
    title: pageContext.name,
    author: "Frends team"
  }


  const color = "orange"
  const colorAsHEX = data.site.siteMetadata.colors.orange;

  const numbersIcons = [
    grey_outline_square,
    grey_outline_circle,
    grey_outline_cylinder,
    grey_outline_rectangle,
    grey_outline_transcript
  ];

  const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

  return (
    <Layout title={`${name}`} transparentNavigation={true} isDark={true} isArticle={true} seoData={seoData} location={location}>
      <main id="content" style={{ marginTop: "-80px" }}>
        <section className={`hero is-fullheight ${color !== null ? `is-${color}-tertiary-bg` : "is-orange-tertiary-bg"}`}>
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-three-fifths is-offset-one-fifth">
                  <h1 className={`has-text-centered font-bold size-66 line-height-70 ${color !== null ? `is-${color}` : "is-orange"}`}>{name}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`section ${color !== null ? `is-${color}-article-gradient` : "is-orange-article-gradient"}`}>
          <div className="container">
            <div className="columns">
              <div className={`column is-three-fifths is-offset-one-fifth article-content content ${color !== null ? `is-${color}` : "is-orange"}`}>
                <h2>Introduction</h2>
                <ContentHTMLWithHoverPopUps
                  contentHTML={introduction.childMarkdownRemark.html} 
                  popUpItems={definedTermsPopUpItems}
                />
              </div>
            </div>
            <div className="columns has-text-centered padding-y-50">
              <div className="column is-three-fifths is-offset-one-fifth">
                <img src={blockquote_start} alt="" style={{ backgroundColor: colorAsHEX }} />
                <h3 className={`blockquote is-orange size-36 line-height-40`}>
                  {slogan}
                </h3>
                <img src={blockquote_end} alt="" style={{ backgroundColor: colorAsHEX }} />
              </div>
            </div>
            <div className="columns">
              {keyFigures.map((number, i) => (
                <div className="column" key={i}>
                  <div style={{ position: "relative", textAlign: "center" }}>
                    <img src={numbersIcons[i]} alt="" style={{ width: "100%" }} />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                      }}
                      className="content"
                    >
                      <h2 className={`title is-${color}`}>{number}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="columns">
              <div className={`column is-three-fifths is-offset-one-fifth article-content content ${color !== null ? `is-${color}` : "is-orange"}`}>
                <h2>Service Offering</h2>
                <ContentHTMLWithHoverPopUps
                  contentHTML={serviceOffering.childMarkdownRemark.html} 
                  popUpItems={definedTermsPopUpItems}
                />
                <h2>Contact</h2>
                <div dangerouslySetInnerHTML={{ __html: contact.childMarkdownRemark.html }} />
              </div>
            </div>
          </div>
        </section>
        {pageLinkLists !== null && (
          <section className={`section is-medium is-${color}-bg`}>
            <div className="container content">
              <div className="columns">
                <div className="column is-three-fifths is-offset-one-fifth">
                  <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
  site {
    siteMetadata {
      colors {
        orange
      }
    }
  }
  contentfulCoSellPartner(contentful_id: {eq: $contentful_id}) {
    createdAt
    updatedAt
    slogan
    name
    keyFigures
    pageLinkListHeader
    pageLinkLists {
      linkList {
        ... on ContentfulWebinarV2 {
          __typename
          id
          title
          webinarUrl
          webinarFreeText {
            childMarkdownRemark {
              excerpt
            }
          }
          webinarImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulServicesSubpage {
          __typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulLanding {
          __typename
          id
          title
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCustomerType {
          __typename
          id
          name
          slug
          ingress {
            childMarkdownRemark {
              excerpt
            }
          }
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
        ... on ContentfulCustomer {
          __typename
          id
          name
          description
          slug
          heroImage {
            gatsbyImageData(height: 400, width: 400)
          }
        }
        ... on ContentfulCoSellPartner {
          __typename
          id
          name
          slug
          slogan
        }
        ... on ContentfulArticle {
          __typename
          id
          title
          articleUrl
          subtitle
          heroImage {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      title
    }
    introduction {
      childMarkdownRemark {
        html
      }
    }
    serviceOffering {
      childMarkdownRemark {
        html
      }
    }
    contact {
      childMarkdownRemark {
        html
      }
    }
  }
  bg: file(relativePath: {eq: "images/purple_bg.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  allContentfulDefinedTerm(
    filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
    ) {
    nodes {
        slug
        title
        description {
            childMarkdownRemark {
                html
                excerpt
            }
        }
    }
  }
}
`
